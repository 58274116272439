import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { LiveAssignmentComponent } from '../secure-pages/live-assignment/live-assignment.component';

@Injectable({
    providedIn: 'root'
})
export class LiveAssignmentService implements CanDeactivate<LiveAssignmentComponent> {

    canDeactivate(component: LiveAssignmentComponent): boolean {
        if (confirm('Are you sure you want to discard your changes?')) {
            component.submitExam(true);
            return true;
        }
    }

    constructor() {
    }
}
