export const GlobalVariable = {

   // GMAIL_KEY: '751931798492-nfj2bluabvumgofhq6b8jgbsdn5fl38l.apps.googleusercontent.com',
   GMAIL_LOGIN_KEY: '155397028646-9kkgr1iuita1497r6tr149hhe8v8evuu.apps.googleusercontent.com',
   // FB_LOGIN_KEY: '445336129411681',
   FB_LOGIN_KEY: '710113023516174',
   SITE_NAME: 'MCCQE1 and NAC OSCE examination',
   PATTERNS: {
      email: '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/',
      colors: '/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/'
   },
   tokenKey: 'medical_exam_token',
   userData: 'medical_exam_user_data',
   selectedLanguage: 'medical_exam_language',
   french: {
      short: 'fr',
      full: 'FRENCH'
   },
   english: {
      short: 'en',
      full: 'ENGLISH'
   },
   PAYPAL_CLIENT_ID: 'AY_Lh59zXx0tWXnM-hjEQYIh4SheYdd2HhT5lvLXJtR1p_N96IiN0uaGxonAeYCxQDJrlC0Wx3drlJid',
   PAYPAL_CURRENCY: 'CAD',
   stripTest: 'pk_test_51M7gn0H01hkZzEKBcg8d5KnWvphsVfJwtvOv7jRdHnZ9E0ylHET7PWHkzCCnwKrade35PN96O5latikIfKqLjVw0008llPvInp'


};



// pk_test_51M7gn0H01hkZzEKBcg8d5KnWvphsVfJwtvOv7jRdHnZ9E0ylHET7PWHkzCCnwKrade35PN96O5latikIfKqLjVw0008llPvInp
//
// sk_test_51M7gn0H01hkZzEKBnnxAln3ereP8n0I8FqbwrcGY2SRYzWi9s7tn9HLCurubPnQWwPvvyk7LXENYcyUizD2ALovV006eXHetB3
//
// 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
//
// pk_live_51M7gn0H01hkZzEKBjrKRIMgqm7L6Jyxy28dY9y908BZIajXzCWXns0FVjrtUev20M61BUNIMOjDR0yoDgQarhEQ200r0KjdZ5K
