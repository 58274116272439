import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// modules
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SharedModule } from './shared/shared.module';

// interceptor
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LoaderInterceptor } from './services/interceptors/loader.interceptor';
import { JwtInterceptor } from './services/interceptors/jwt.interceptor';

// components
import { AppComponent } from './app.component';
import { LoaderComponent } from './loader/loader.component';

// firebase
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';

// Social Login

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
   GoogleLoginProvider,
   FacebookLoginProvider
} from 'angularx-social-login';

import { ModalModule } from 'ngx-bootstrap/modal';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { FooterModule } from './footer/footer.module';
import { GlobalVariable } from './core/global';
import { IndexPageComponent } from './index-page/index-page.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsComponent } from './terms/terms.component';
import { FormsModule } from '@angular/forms';

const config = {
   provide: 'SocialAuthServiceConfig',
   useValue: {
      autoLogin: false,
      providers: [
         {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              GlobalVariable.GMAIL_LOGIN_KEY
            )
         },
         {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              GlobalVariable.FB_LOGIN_KEY
            )
         }
      ]
   } as SocialAuthServiceConfig
};

export function provideConfig() {
   return config;
}

@NgModule({
   declarations: [
      AppComponent,
      LoaderComponent,
      LandingPageComponent,
      IndexPageComponent,
      SubscriptionsComponent,
      AboutUsComponent,
      PrivacyPolicyComponent,
      TermsComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      SharedModule,
      ModalModule.forRoot(),
      SocialLoginModule,
      HttpClientModule,
      BrowserAnimationsModule,
      ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
      AngularFireMessagingModule,
      AngularFireModule.initializeApp(environment.firebase),
      FooterModule,
      FormsModule

   ],
   providers: [
      config,
      {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
      {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
      {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}
      // {provide: AuthServiceConfig, useFactory: provideConfig}
   ],
   bootstrap: [AppComponent]
})
export class AppModule {
}
