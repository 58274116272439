import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { HttpService } from '../../../services/http/http.service';
import { MessagingService } from '../../../services/message/message.service';
import { GlobalVariable } from '../../../core/global';

@Component({
    selector: 'app-plans',
    templateUrl: './confirm-reattempt-modal.component.html'
})
export class ConfirmReattemptComponent implements OnInit {

    public onClose: Subject<{}> = new Subject();
    modalData: any = {};
    public global = GlobalVariable;
    language = '';

    constructor(public bsRef: BsModalRef, private util: UtilityService, public http: HttpService,
                public message: MessagingService) {
    }

    ngOnInit() {
        this.language = this.util.getLocalData(GlobalVariable.selectedLanguage);
    }

    clickFun(flag) {
        this.onClose.next({accept: flag});
        this.bsRef.hide();
    }

}
