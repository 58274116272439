export const SuccessErrorConst = {

    loginAgin: 'Please login Again!',
    pleaseFillRequiredData: 'Please fill all the required data!',
    pleaseSelectDate: 'Please select date!',
    pleaseSelectTimeSlot: 'Please select time slot!',
    pleaseSelectTicket: 'Please select ticket!',
    pleaseSelectUser: 'Please select number of users!',
    pleaseEnterCode: 'Please enter coupan code!',
    endedSuccess: 'Ended Successfully!',

}
