import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GlobalVariable } from 'src/app/core/global';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { ApiUrl } from '../../../core/apiUrl';
import { HttpService } from '../../../services/http/http.service';
import { SuccessErrorConst } from '../../../core/successErrorConst';
import { MessagingService } from '../../../services/message/message.service';

@Component({
    selector: 'app-plans',
    templateUrl: './meet-details-modal.component.html'
})
export class MeetDetailsModalComponent implements OnInit {

    public onClose: Subject<{}>;
    public planData;
    public global = GlobalVariable;
    language = '';
    selectedPlan: any = {};
    modalData: any = {};

    constructor(public bsRef: BsModalRef, private util: UtilityService, public http: HttpService,
                public message: MessagingService) {
    }

    ngOnInit() {
        this.language = this.util.getLocalData(GlobalVariable.selectedLanguage);
        this.onClose = new Subject();
    }

    resetStatus() {

    }

    closeModal() {
        this.onClose.next(this.selectedPlan._id);
        this.bsRef.hide();
    }

    joinMeeting(data) {
        const request = {
            language: 'ENGLISH',
            meeting_id: data._id,
            timezone: 'Asia/Kolkata'
        };
        this.http.putData(ApiUrl.user_start_call, request).subscribe(res => {
            this.openPopUp(data);
        });
    }

    endCall(data) {
        const request = {
            language: 'ENGLISH',
            meeting_id: data._id,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        this.http.putData(ApiUrl.user_end_call, request).subscribe(res => {
            this.message.toast('success', SuccessErrorConst.endedSuccess);
        });
    }

    openPopUp(data) {
        const self = this;
        this.disableParentWin();
        const win = window.open(data.url, 'mywindow', 'status=1,toolbar=0,width=4000,height=4000');
        // tslint:disable-next-line:only-arrow-functions
        win.onunload = function () {
            self.endCall(data);
        };
        win.focus();
        this.checkPopUpClosed(win);
    }

    checkPopUpClosed(win) {
        const timer = setInterval(() => {
            if (win.closed) {
                clearInterval(timer);
                this.enableParentWin();
            }
        }, 1000);
    }

    /*Function to enable parent window*/
    enableParentWin() {
        window.document.getElementById('mainDiv')[`class`] = '';
    }

    /*Function to enable parent window*/
    disableParentWin() {
        window.document.getElementById('mainDiv')[`class`] = 'disableWin';
    }
}
