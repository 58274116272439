import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { GlobalVariable } from 'src/app/core/global';
import { Router, ActivatedRoute } from '@angular/router';
import { MyProfileComponent } from '../my-profile/my-profile.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscriber } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';
import { ApiUrl } from 'src/app/core/apiUrl';
import { MessagingService } from 'src/app/services/message/message.service';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';

@Component({
    selector: 'app-paypal',
    templateUrl: './paypal.component.html',
    styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {

    public payPalConfig?: IPayPalConfig;
    showCancel = false;
    showError = false;
    showSuccess = false;
    paypalSubmit;
    selectedPlan: any = {};
    @Output() changeEvent = new EventEmitter<string>();
    @Input() sendData: any = {};

    constructor(public util: UtilityService, public router: Router, private http: HttpService,
                public activatedRoute: ActivatedRoute, private modalService: BsModalService,
                private message: MessagingService) {

    }

    ngOnInit() {
        this.initConfig();
    }

    private initConfig(): void {
        this.payPalConfig = {
            currency: 'EUR',
            clientId: 'sb',
            createOrderOnClient: (data) => <ICreateOrderRequest>{
                intent: 'CAPTURE',
                purchase_units: [
                    {
                        amount: {
                            currency_code: 'EUR',
                            value: '9.99',
                            breakdown: {
                                item_total: {
                                    currency_code: 'EUR',
                                    value: '9.99'
                                }
                            }
                        },
                        items: [
                            {
                                name: 'Enterprise Subscription',
                                quantity: '1',
                                category: 'DIGITAL_GOODS',
                                unit_amount: {
                                    currency_code: 'EUR',
                                    value: '9.99'
                                }
                            }
                        ]
                    }
                ]
            },
            advanced: {
                commit: 'true'
            },
            style: {
                label: 'paypal',
                layout: 'vertical'
            },
            onApprove: (data, actions) => {
                console.log('onApprove - transaction was approved, but not authorized', data, actions);
                actions.order.get().then(details => {
                    console.log('onApprove - you can get full order details inside onApprove: ', details);
                });
            },
            onClientAuthorization: (data) => {
                console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
                this.showSuccess = true;
            },
            onCancel: (data, actions) => {
                console.log('OnCancel', data, actions);
            },
            onError: err => {
                console.log('OnError', err);
            },
            onClick: (data, actions) => {
                console.log('onClick', data, actions);
            }
        };
    }

    resetStatus() {

    }

    submitData() {
        this.changeEvent.emit('myyyyyyyyyyyy');
    }

}
