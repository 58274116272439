import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'translate1'
})
export class Translate1Pipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): unknown {
        return value;
    }
}
