import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../services/utility/utility.service';
import { ApiUrl } from '../core/apiUrl';
import { HttpService } from '../services/http/http.service';
import { MessagingService } from '../services/message/message.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

declare const $: any;

@Component({
   selector: 'app-privacy-policy',
   templateUrl: './privacy-policy.component.html',
   styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

   constructor(public util: UtilityService, private http: HttpService, private messageService: MessagingService, private fb: FormBuilder ) {
   }

   ngOnInit() {

      $(document).ready(function () {
         $('.menu-items li a').click(function () {
            $('li a').removeClass('actived');
            $(this).addClass('actived');
         });
      });

      $(document).on('click', '#cancel', function () {
         parent.history.back();
         $.mobile.back();
      });
this.makeForm()
   }


   ShowError
   submitForm() {
      let obj = this.form.value
      console.log(obj)
      if (this.form.valid) { 
         if (!obj.message) {
            delete obj.message
        }  
          this.http.postDataWithOutLang(ApiUrl.send_mail_user_info, obj).subscribe(res => {
          console.log(res)
          this.ShowError = true;
      this.messageService.toast('success', 'Our team will connect with you shortly')
      })
      } else {
          this.ShowError = true;
          this.form.markAllAsTouched();
  
      }
   }
   
   makeForm() {
      this.form = this.fb.group({
          name: [[], Validators.required],
          email: [[], [Validators.required, Validators.email]],
          phone_number: [[], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
          message: ['']
      }) 
   }

   emailIsValid() {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    }
    
    form: FormGroup
 email
 submitEmail() {
   console.log(this.email)
   let obj = {
      message: this.email
   }
this.http.postDataWithOutLang(ApiUrl.send_mail, obj).subscribe(res => {
console.log(res)
this.email = ''
this.messageService.toast('success', 'Mail has been successfully registered')

})
}



}
