export const ApiUrl = {
    signup: '/User/signup',
    login: '/User/login',
    forgot_password: '/User/forgot_password',
    otp_verification: '/User/otp_verification',
    resend_otp: '/User/resend_otp',
    list_cdm_exam: '/User/list_cdm_exam',
    home_cdm_exams: '/User/home_cdm_exams',
    set_password: '/User/set_password',
    capture_payment: '/User/capture_payment',
    manage_cdm_exam: '/User/manage_cdm_exam',
    check_promo_code: '/User/check_promo_code',
    edit_pofile: '/User/edit_profile',
    list_universities: '/OpenApi/list_universities',
    list_languages: '/OpenApi/list_languages',
    change_password: '/User/change_password',
    access_token_login: '/User/access_token_login',
    update_device_token: '/User/update_device_token',
    logout: '/User/logout',
    list_plans: '/User/list_plans',
    send_mail: '/OpenApi/send_mail',
    send_mail_user_info: '/OpenApi/send_mail_user_info',
    generate_payment_link: '/User/genrate_payment_link',
    home_cdm_exam_details: '/User/home_cdm_exam_details',
    purchase_plan: '/User/purchase_plan',
    list_teacher_avilable_slots: '/User/list_teacher_avilable_slots',
    list_oscer: '/User/list_oscer',
    list_home: '/User/home',
    list_oscer_cases: '/User/list_oscer_cases',
    social_login: '/User/social_login',
    list_oscer_topics: '/User/list_oscer_topcis',
    list_exams: '/User/list_exmas',
    list_exams_question_count: '/User/list_exmas_question_count',
    manage_exams: '/User/manage_exam',
    list_progress: '/User/my_progress',
    list_question_bank: '/User/list_qstn_bank',
    list_question_details: '/User/qstn_bank_details',
    view_test: '/User/view_test',
    list_qstn_plan: '/User/list_qstn_bank_plans',
    create_test: '​/User/create_test',
    submit_qstn_bank: '/User/manage_test',
    test_details: '/User/user_test_details',
    test_progress: '/User/my_test_progress',
    my_test_progress: '/User/my_test_clear',

    list_study_material_details: '/User/study_material_details',
    edit_profile: '/User/edit_profile',
    user_change_password: '​/User/change_password',
    history_details: '/User/oscer_history_details',
    test_history_details: '​/User/test_history_details',
    test_reports: '​/User/test_reports',
    flash_cards: '/User/set_flash_cards',
    list_flash_cards: '/User/list_flash_cards',
    list_meetings: '/User/list_meetings',
    list_study_plan: '/User/list_study_plans',
    create_meetings: '/User/create_new_meeting',
    meeting_details: '/User/meeting_details',
    cdm_exam_history_details: '/User/cdm_exam_history_details',
    manage_cdm_exam_report: '/User/manage_cdm_exam_report',
    cdm_exam_report: '/User/cdm_exam_report',
    list_teachers: '/User/list_teachers',
    cdm_exam_report_details: '/User/cdm_exam_report_details',
    list_notification: '/User/list_notification',
    delete_notification: '/User/delete_notification',
    user_end_call: '/User/end_call',
    user_start_call: '/User/start_call',
    add_study_plan: '/User/add_study_plan',
    // upload: 'https://api.buildingenerlytics.com/Admin/upload_attachment',
    upload: 'https://fileupload.mccqbank.com/Upload/file_upload',
    list_study_plan_details: '/User/study_plan_details',
    study_plan_qstn: '/User/study_plan_qstns',
    manage_study_plan: '/User/manage_sp_qstns',
    list_study_plan_qstn: '/User/list_subscribed_qb',
    buy_meeting_admin: '/User/buy_meeting_admin',
    list_category: '/User/list_qstn_categories',
    logInSignUpPage: '/OpenApi/list_tutorials',
    update_password: '/OpenApi/update_password',
    add_edit_university: '/User/add_edit_university'
};
