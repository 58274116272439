import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../services/utility/utility.service';
import { Router } from '@angular/router';
import { HttpService } from '../services/http/http.service';
import { ApiUrl } from '../core/apiUrl';
import { MessagingService } from '../services/message/message.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';



declare const $: any;


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor( private utilService: UtilityService, 
    private router: Router, 
    private http: HttpService, 
    private messageService: MessagingService,
    private fb: FormBuilder
    ) { }

  ngOnInit() {
    this.aboutuss();
    this.firstslider();
    this.secondslider();
  }

  aboutuss(){
    $(document).ready(function(){
      $('.menu-items li a').click(function(){
          $('li a').removeClass("actived");
          $(this).addClass("actived");
      });
      });
  this.makeForm()
    }


    form: FormGroup
  ShowError
  submitForm() {
     let obj = this.form.value
     console.log(obj)
     if (this.form.valid) {  
      if (!obj.message) {
        delete obj.message
    } 
         this.http.postDataWithOutLang(ApiUrl.send_mail_user_info, obj).subscribe(res => {
         console.log(res)
         this.ShowError = true;
     this.messageService.toast('success', 'Our team will connect with you shortly')
     })
     } else {
         this.ShowError = true;
         this.form.markAllAsTouched();
 
     }
  }
  
  makeForm() {
     this.form = this.fb.group({
         name: [[], Validators.required],
         email: [[], [Validators.required, Validators.email]],
         phone_number: [[], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
         message: ['']
     }) 
  }



emailIsValid() {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
}


  email
  submitEmail() {
    console.log(this.email)
    let obj = {
       message: this.email
    }
this.http.postDataWithOutLang(ApiUrl.send_mail, obj).subscribe(res => {
 console.log(res)
 this.email = ''
this.messageService.toast('success', 'Mail has been successfully registered')

})
 }


  goToThisArea(s) {
    console.log(s)
    this.router.navigate(['/index-page'])
    this.utilService.shareStufff.next(`#${s}`)

    // this.onClose.next(s)
  }


  firstslider(){
    $('.autoplay').slick({
      infinite: true,
      slidesToShow: 2,
      arrows: true,
      slidesToScroll: 1,
      lazyLoad: 'ondemand',
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
          {
          breakpoint: 991,
          settings: {
              slidesToShow: 1,
              autoplaySpeed: 4000,
          }}
          ]
      });
  }

  secondslider(){
    $('.wrapsider').slick({
      slidesToShow: 2,
      arrows: true,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      dots: true,
      centerMode: true,
      focusOnSelect: true,
      responsive: [
          {
          breakpoint: 991,
          settings: {
              slidesToShow: 1,
              autoplaySpeed: 4000,
          }}
          ]
      });
  }

}
