import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as data from './replace-text.json';

const tempArr: any = data;

@Pipe({name: 'replace'})
export class ReplacePipe implements PipeTransform {

    transform(value: string): string {

        if (!value) {
            return value;
        }
        let changed: string;
        changed = tempArr.default[value];
        return (changed ? changed : value);
    }
}
