import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {GlobalVariable} from '../core/global';
import {HttpService} from '../services/http/http.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {UtilityService} from '../services/utility/utility.service';
import {DatePipe} from '@angular/common';
import { ApiUrl } from '../core/apiUrl';
import { MessagingService } from '../services/message/message.service';

declare const $: any;

@Component({
    selector: 'app-landing-page',
    templateUrl: './index-page.component.html',
    styleUrls: ['./index-page.component.scss']
})
export class IndexPageComponent implements OnInit, OnDestroy {

    language = false;
    
    constructor(public util: UtilityService, 
        private http: HttpService, 
        private router: Router, 
        private renderer: Renderer2, 
        private scrollToElement: ElementRef,
        private messageService: MessagingService,
        private fb: FormBuilder
        ) {
          
        this.util.setLocalData(GlobalVariable.selectedLanguage, GlobalVariable.english.short);
    }
    
    barVisible = false
  
    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event) {
            
        
        let doc = $('.menu-items li a.transitionTrackerOne')
     
const scrollPosition = window.scrollY || window.pageYOffset;
  
      const triggerThreshold = 1908; 
      
      this.getScreenSize();
        this.performAction(triggerThreshold, scrollPosition);

    }


    form: FormGroup
  
    screenWidth
screenHeight


    private getScreenSize(): void {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    }


    // private checkScreenResolution(): void {

    //     if (this.screenWidth < 1300) {

    //     console.log('Tablet layout');

    //     } 

    // }

    widthOfhr = '51'
    marginLeftOfHr = '0'
    performAction(scrollTo, scrollTwo) {
        console.log(scrollTwo)
        if (this.screenWidth < 1300) {
            if (scrollTwo >= 6780) {
                console.log("It's sixth");
                let home = $('.transitionTrackerSix')
                let homeW = home.width()
                this.widthOfhr = homeW;
                this.marginLeftOfHr =  '45.2%'
      
           
              } else if (scrollTwo >= 6129) {
                console.log("It's fifth");
                let home = $('.transitionTrackerFive')
                let homeW = home.width()
                this.widthOfhr = homeW;
                this.marginLeftOfHr =  '32%'
      
              } else if (scrollTwo >= 5133) {
                console.log("It's Fourth");
                let home = $('.transitionTrackerFour')
                let homeW = home.width()
                this.widthOfhr = homeW;
                this.marginLeftOfHr =  '23.2%'
                
              } else if (scrollTwo >= 4341) {
                console.log("It's Third");
      
                let home = $('.transitionTrackerThree')
                let homeW = home.width()
                this.widthOfhr = homeW;
                this.marginLeftOfHr =  '17.3%'
      
              } else if (scrollTwo >= 2597) {
                console.log("It's second");
                let home = $('.transitionTrackerTwo')
                let homeW = home.width()
                    this.widthOfhr = homeW;
                this.marginLeftOfHr =  '7%'
              } else {
                console.log("Invalid position");
                let home = $('.transitionTrackerOne')
                let homeW = home.width()
                    this.widthOfhr = homeW;
                    this.marginLeftOfHr = '0'
              }

        } else {

        
        if (scrollTwo >= 6780) {
          console.log("It's sixth");
          let home = $('.transitionTrackerSix')
          let homeW = home.width()
          this.widthOfhr = homeW;
          this.marginLeftOfHr =  '46.2%'

     
        } else if (scrollTwo >= 6129) {
          console.log("It's fifth");
          let home = $('.transitionTrackerFive')
          let homeW = home.width()
          this.widthOfhr = homeW;
          this.marginLeftOfHr =  '33%'

        } else if (scrollTwo >= 5133) {
          console.log("It's Fourth");
          let home = $('.transitionTrackerFour')
          let homeW = home.width()
          this.widthOfhr = homeW;
          this.marginLeftOfHr =  '24%'
          
        } else if (scrollTwo >= 4341) {
          console.log("It's Third");

          let home = $('.transitionTrackerThree')
          let homeW = home.width()
          this.widthOfhr = homeW;
          this.marginLeftOfHr =  '17.8%'

        } else if (scrollTwo >= 2597) {
          console.log("It's second");
          let home = $('.transitionTrackerTwo')
          let homeW = home.width()
              this.widthOfhr = homeW;
          this.marginLeftOfHr =  '7.5%'
        } else {
          console.log("Invalid position");
          let home = $('.transitionTrackerOne')
          let homeW = home.width()
              this.widthOfhr = homeW;
              this.marginLeftOfHr = '0'
        }
    }
      }
      
      routeChanged(route) {
        window.scrollTo(0, 0);
        this.router.navigate([route])
      }
      
      burgerCheck
      checkHam(e) {
        console.log(this.burgerCheck)
        this.burgerCheck = !this.burgerCheck
        // e.target.checked = this.burgerCheck
      }



      checkChange() {
        this.burgerCheck = false
    }



    ngOnInit() {


        const that = this;
        setTimeout(() => {
            that.setData();
            this.util.shareStufff.subscribe(res => {
                console.log(res)

                if (res) {

                    if (res == '#MCCQE1') {
                        
                        $('html, body').animate({
                            scrollTop: 2597
                        }, 1000);
                    }
                    if (res == '#CDM') {

                        $('html, body').animate({
                            scrollTop: 4341
                        }, 1000);
                        
                    }
                       if (res == '#OSCE') {

                        
                        $('html, body').animate({
                            scrollTop:  5133
                        }, 1000);
                    }
                    if (res == '#Mentorship') {

                        
                        $('html, body').animate({
                            scrollTop: 6129
                        }, 1000);
                    }

                     if (res == '#Crash') {
                        $('html, body').animate({
                            scrollTop: 6780
                        }, 1000);
                        
                        
                    }

                }

            })
        
        }, 1000)
        
           this.makeForm()


        
        // const language = this.util.getLocalData(GlobalVariable.selectedLanguage);
        // if (language === GlobalVariable.french.short) {
        //     this.language = true;
        // }
    }

    // setLang() {
    //     this.translate.setDefaultLang(this.util.getLocalData(GlobalVariable.selectedLanguage));
    //     this.translate.use(this.util.getLocalData(GlobalVariable.selectedLanguage));
    // }
//



 ngOnDestroy() {
    this.removeEventListeners();
 }

 makeForm() {
    this.form = this.fb.group({
        name: [[], Validators.required],
        email: [[], [Validators.required, Validators.email]],
        phone_number: [[], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
        message: ['']
    }) 
 }
 ShowError
 submitForm() {
    let obj = this.form.value
    console.log(obj)
    if (this.form.valid) {   
        if (!obj.message) {
            delete obj.message
        }
        this.http.postDataWithOutLang(ApiUrl.send_mail_user_info, obj).subscribe(res => {
        console.log(res)
        this.ShowError = true;
    this.messageService.toast('success', 'Our team will connect with you shortly')
    })
    } else {
        this.ShowError = true;
        this.form.markAllAsTouched();

    }
 }

 email
 submitEmail() {
   console.log(this.email)
   let obj = {
      message: this.email
   }
this.http.postDataWithOutLang(ApiUrl.send_mail, obj).subscribe(res => {
console.log(res)
this.email = ''
this.messageService.toast('success', 'Mail has been successfully registered')

})
}


emailIsValid() {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
  }

 removeEventListeners() {
    $(function () {
        console.log('last function hitted');

        // e.preventDefault();
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
    });
    console.log(window.scrollY)
 }


    setData() {
        // $(document).ready(function () {
        //     $('.menu-items li a').click(function () {
        //         $('li a').removeClass("actived");
        //         $(this).addClass("actived");
        //     });
        // });


        $('.imgsildeer').slick({
            infinite: true,
            slidesToShow: 5,
            arrows: true,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,

                    }
                },
                {
                    breakpoint: 482,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });


        $(function () {
            'use strict';
            $('body').css('paddingTop', $('.navbar').innerHeight());

            $('.navbar li a').click(function () {
                console.log('first function hitted');
                console.log($('.navbar li a'));
                $('html, body').animate({
                    scrollTop: $($(this).data('scroll')).offset().top - 90
                }, 1000);

                $('.navbar a').removeClass('active');
                $(this).addClass('active');

                //$(this).addClass('active').parent().siblings().find('a').removeClass('active');
            });

            $(window).scroll(function () {
                var winScrollTop = $(this).scrollTop();
                $('.block').each(function () {
                    if (winScrollTop > $(this).offset().top) {
                        var blockId = '#' + $(this).attr('id');
                        $('.navbar li a').each(function () {
                            if ($(this).attr('data-scroll') === blockId) {
                                $('.navbar a').removeClass('active');
                                $(this).addClass('active');
                                /*
                                $(this).addClass('active').parent().siblings().find('a').removeClass('active');
                                */

                            }
                        });
                    }
                });
                //Scroll Element
                var toTop = $('.toTop');
                if ($(window).scrollTop() >= 500) {
                    if (toTop.is(':hidden')) {//For performance
                        toTop.fadeIn().css('display', 'flex');
                    }
                } else {
                    toTop.fadeOut();
                }
            });

            $('.toTop').click(function (e) {
                console.log('last function hitted');

                e.preventDefault();
                $('html, body').animate({
                    scrollTop: 0
                }, 1000);
            });
        });















        $(document).ready(function () {

            // Animation
        
            // new WOW().init();
        
            // Smoth Scroll Animation
        
            $(".smoth-scroll").on('click', function (event) {
        
                // Make sure this.hash has a value before overriding default behavior
                if (this.hash !== "") {
                    // Prevent default anchor click behavior
                    event.preventDefault();
        
                    // Store hash
                    var hash = this.hash;
        
                    // Using $'s animate() method to add smooth page scroll
                    // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                    $('html, body').animate({
                        scrollTop: $(hash).offset().top
                    }, 800, function () {
        
                        // Add hash (#) to URL when done scrolling (default click behavior)
                        window.location.hash = hash;
                    });
                } // End if
            });
        
            $(".mob_menu a").click(function () {
                $(this).addClass("open");
                $(".UserMenu").addClass("active");
                $(".menu_overlay").addClass("active");
                $(".header").addClass("active");
                $("body").addClass("hide_scrll");
            });
        
            $(".menu_overlay").click(function () {
                $(".mob_menu a").removeClass("open");
                $(".UserMenu").removeClass("active");
                $(".menu_overlay").removeClass("active");
                $(".header").removeClass("active");
                $("body").removeClass("hide_scrll");
            });
        
            $(".sidebar_cntmob a").click(function () {
                $(".sidebar_overlay").toggleClass("active");
                $(".sidebar_contacts").addClass("active");
                $("body").addClass("hide_scrll");
            });
        
            $(".sidebar_overlay").click(function () {
                $(".sidebar_contacts").removeClass("active");
                $(this).removeClass("active");
                $("body").removeClass("hide_scrll");
            });
        
            $(".headerUserboxMain.dropdown-toggle").on("mouseenter", function () {
                if (!$(this).parent().hasClass("show")) {
                    $(this).click();
                }
            });
        
            /*   Post Slider   */
        
            $('.postbox_slider, .heroslider').slick({
                arrows: false,
                dots: true,
                infinite: true,
                autoplay: true,
                slidesToShow: 1,
                slidesToScroll: 1
            });
        
            $('.dashNavSlide').slick({
                dots: false,
                speed: 300,
                autoplay: true,
                infinite: true,
                variableWidth: true,
                slidesToShow: 10,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 7
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 4
                        }
                    },
                    {
                        breakpoint: 479,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ]
            });
        
            $('.coupnSlider').slick({
                dots: false,
                arrows: false,
                speed: 300,
                autoplay: false,
                infinite: true,
                slidesToShow: 2,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            });
        
            $('.testiSlider').slick({
                dots: true,
                arrows: false,
                autoplaySpeed: 1500,
                autoplay: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1
            });
        
            setTimeout(function () {
                $('.poll_slider').slick({
                    arrows: false,
                    dots: false,
                    infinite: true,
                    autoplay: true,
                    slidesToShow: 2,
                    slidesToScroll: 1
                });
            }, 100);
        
            $('.uisec_slider').slick({
                infinite: true,
                autoplay: true,
                pauseOnHover: false,
                arrows: true,
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1 
            });
        
            $(document).on('hidden.bs.modal', function (event) {
                if ($('.modal:visible').length) {
                    $('body').addClass('modal-open');
                }
            });
        
            //    if ($(window).width() > 767) {
            //        $('#app_slide').carousel({
            //            pause: false,
            //            interval: 1500
            //        });
            //        new WOW().init();
            //    };
        
            $("#app_slide .carousel-indicators li").click(function () {
                var goto = Number($(this).attr('data-slide-to'));
                $("#app_slide").carousel(goto);
            });
            
            $("#blueSlide .carousel-indicators .col-6").hover(function () {
                var goto = Number($(this).attr('data-slide-to'));
                $("#blueSlide").carousel(goto);
            });
            
            $("#aiSlide .carousel-indicators li").hover(function () {
                var goto = Number($(this).attr('data-slide-to'));
                $("#aiSlide").carousel(goto);
                $("#aiSlide1").carousel(goto);
            });
            
        
            $("#blueSlidee .carousel-indicators .new-slide").click(function () {
                var goto = Number($(this).attr('data-slide-to'));
                $("#blueSlidee").carousel(goto);
                $("#blueSlide1").carousel(goto);
            });
        
            $("#CDM .carousel-indicators li").click(function () {
                var goto = Number($(this).attr('data-slide-to'));
                $("#CDM").carousel(goto);
                $("#CDM1").carousel(goto);
            });
        
            $("#OSCE .carousel-indicators li").click(function () {
                var goto = Number($(this).attr('data-slide-to'));
                $("#OSCE").carousel(goto);
                $("#OSCE1").carousel(goto);
                $("#OSCE2").carousel(goto);
            });
        
        });













    }

}
