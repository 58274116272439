import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-create-meetings',
    templateUrl: './image-modal.component.html',
    styleUrls: ['./image-modal.component.scss']
})

export class ImageModalComponent {

    modalData: any = {};

    constructor(public bsRef: BsModalRef) {
    }

    closeModal() {
        this.bsRef.hide();
    }
}
