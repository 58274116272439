import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

// services
import { LoaderService } from '../loader/loader.service';
import { UtilityService } from '../utility/utility.service';
import { GlobalVariable } from 'src/app/core/global';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(
            private http: HttpClient, private util: UtilityService,
            private loader: LoaderService
    ) {
    }

    /**
     * @method (get http request)
     * @param url (api url)
     * @param data (all search params)
     * @param backGroundUrl (don't want to show loader for any request pass true)
     */
    getData(url: string, data?: any, backGroundUrl?: boolean): Observable<any> {

        this.setLanguage(data);

        const searchParams = this.appendParams(data);
        const apiUrl = `${environment.apiBaseUrl}${url}`;
        if (backGroundUrl) {
            this.loader.backGroundUrls.push(apiUrl);
        }
        return this.http.get(apiUrl, {params: searchParams}).pipe(map((response: any) => {
            return response;
        }));
    }

    /**
     * @method (post http request)
     * @param url (api url)
     * @param data (data which we have to pass to server)
     */

postDataWithOutLang(url: string, data, formData?: boolean, backGroundUrl?: boolean) {

    const apiUrl = `${environment.apiBaseUrl}${url}`;
    const postData = !formData ? data : this.appendFormData(data);
console.log(postData)

    if (backGroundUrl) {
        this.loader.backGroundUrls.push(apiUrl);
    }
    console.log(postData)

    // const body = new HttpParams()
    //   .set('language', postData['language'])
    //   .set('status', postData['status'])
    //   .set('questions',JSON.parse(postData['questions']))
    //   .set('time_spent_frontend', postData['time_spent_frontend'])
    //   .set('user_exam_id', postData['user_exam_id'])
    //   .set('time', postData['time'])
    return this.http.post<any>(apiUrl, postData).pipe(map((response: any) => {
        return response;
    }));
}


postDataWithQuery(url: string, data, formData?: boolean, backGroundUrl?: boolean) {

    const apiUrl = `${environment.apiBaseUrl}${url}`;
    const postData = this.appendParams(data);
console.log(postData)

    if (backGroundUrl) {
        this.loader.backGroundUrls.push(apiUrl);
    }

    // const body = new HttpParams()
    //   .set('language', postData['language'])
    //   .set('status', postData['status'])
    //   .set('questions',JSON.parse(postData['questions']))
    //   .set('time_spent_frontend', postData['time_spent_frontend'])
    //   .set('user_exam_id', postData['user_exam_id'])
    //   .set('time', postData['time'])
    return this.http.post<any>(apiUrl, {}, {params: postData}).pipe(map((response: any) => {
        return response;
    }));
}
    
    postData(url: string, data, formData?: boolean, backGroundUrl?: boolean) {

        console.log(data)
        this.setLanguage(data);
        console.log(data)

        const apiUrl = `${environment.apiBaseUrl}${url}`;
        const postData = !formData ? data : this.appendFormData(data);
console.log(postData)

        if (backGroundUrl) {
            this.loader.backGroundUrls.push(apiUrl);
        }
        console.log(postData)

        // const body = new HttpParams()
        //   .set('language', postData['language'])
        //   .set('status', postData['status'])
        //   .set('questions',JSON.parse(postData['questions']))
        //   .set('time_spent_frontend', postData['time_spent_frontend'])
        //   .set('user_exam_id', postData['user_exam_id'])
        //   .set('time', postData['time'])
        return this.http.post<any>(apiUrl, postData).pipe(map((response: any) => {
            return response;
        }));
    }



    postDataForHeadeAndBody(url: string, data, backGroundUrl?: boolean) {

        this.setLanguage(data);

        const apiUrl = `${environment.apiBaseUrl}${url}`;
        const obj = {
            language: data.language,
            name: data.name,
        }
        // const postData = !formData ? data : this.appendFormData(data?.authorization);
        if (backGroundUrl) {
            this.loader.backGroundUrls.push(apiUrl);
        }

        return this.http.post<any>(apiUrl, obj, {headers: data.authorization}).pipe(map((response: any) => {
            return response;
        }));
    }






    /**
     * @method (put http request)
     * @param url (api url)
     * @param data (data which we have to pass to server)
     */
    putData(url: string, data, formData?: boolean, backGroundUrl?: boolean) {
        this.setLanguage(data);

        const apiUrl = `${environment.apiBaseUrl}${url}`;
        const postData = !formData ? data : this.appendFormData(data);

        if (backGroundUrl) {
            this.loader.backGroundUrls.push(apiUrl);
        }

        return this.http.put<any>(apiUrl, postData).pipe(map((response: any) => {
            return response;
        }));
    }

    

    /**
     * @method (patch http request)
     * @param url (api url)
     * @param data (data which we have to pass to server)
     */
    patchData(url: string, data, formData?: boolean) {
        const apiUrl = `${environment.apiBaseUrl}${url}`;
        const postData = !formData ? data : this.appendFormData(data);
        return this.http.patch(apiUrl, postData).pipe(map((response: any) => {
            return response;
        }));
    }

    /**
     * @method (delete http request)
     * @param url (api url)
     */
    deleteData(url: string) {
        const apiUrl = `${environment.apiBaseUrl}${url}`;
        return this.http.delete(apiUrl).pipe(map((response: any) => {
            return response;
        }));
    }

    /**
     * @method (append formdata)
     * @param myFormData (pass which you want to make formdata type)
     */
    appendFormData(myFormData: { [x: string]: any; }): FormData {
        const fd = new FormData();
        for (const key of Object.keys(myFormData)) {
            if (typeof myFormData[key]) {
                fd.append(key, JSON.stringify(myFormData[key]));
            } else {
                fd.append(key, myFormData[key]);
            }
        }
        return fd;
    }

    appendImageFormData(myFormData: { [x: string]: any; }): FormData {
        const fd = new FormData();
        for (const key of Object.keys(myFormData)) {
            fd.append(key, myFormData[key]);
        }
        return fd;
    }

    /**
     * @method (append params)
     * @param myParams (pass data which you want to make http params)
     */
    appendParams(myParams: { [x: string]: any; }): HttpParams {
        let params = new HttpParams();
        if (!!myParams) {
            for (const key of Object.keys(myParams)) {
                // if (data[key] || key == 'skip')
                params = params.append(key, myParams[key]);
            }
        }
        return params;
    }

    setLanguage(data) {
        const language = this.util.getLocalData(GlobalVariable.selectedLanguage);

        if (!!language) {
            if (language === GlobalVariable.french.short) {
                data.language = GlobalVariable.french.full;
            } else if (language === GlobalVariable.english.short) {
                data.language = GlobalVariable.english.full;
            } else {
                data.language = GlobalVariable.english.full;
            }
        } else {
            data.language = GlobalVariable.english.full;
        }
    }

    /**
     * @method (post http request)
     * @param url (api url)
     * @param data (data which we have to pass to server)
     */
    imageUpload(url: string, data) {
        const postData = this.appendImageFormData(data);
        return this.http.post(url, postData).pipe(map((response: any) => {
            return response;
        }));
    }

}
