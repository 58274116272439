import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { HttpService } from '../../../services/http/http.service';
import { MessagingService } from '../../../services/message/message.service';
import { GlobalVariable } from '../../../core/global';

@Component({
    selector: 'app-plans',
    templateUrl: './study-details-modal.component.html'
})
export class StudyDetailsModalComponent implements OnInit {

    public onClose: Subject<{}> = new Subject();
    modalData: any = {};
    public global = GlobalVariable;
    language = '';

    constructor(public bsRef: BsModalRef, private util: UtilityService, public http: HttpService,
                public message: MessagingService) {
    }

    ngOnInit() {
        this.language = this.util.getLocalData(GlobalVariable.selectedLanguage);
    }

    openAttachment(data) {
        if (this.modalData.file_urls.length) {
            this.modalData.file_urls.forEach(element => {
                this.downlaod(element, element.original.split('.').pop());
            });
        }
    }

    downlaod(element, type) {
        const redirectWindow: any = window.open(element.original, '_blank');
        // tslint:disable-next-line:no-unused-expression
        redirectWindow.location;
    }

    downlaodHTML(data) {
        function downloadCurrentDocument() {
            // tslint:disable-next-line:one-variable-per-declaration
            const base64doc = btoa(unescape(encodeURIComponent(data))),
                    a = document.createElement('a'),
                    e = new MouseEvent('click');

            a.download = 'doc.html';
            a.href = 'data:text/html;base64,' + base64doc;
            a.dispatchEvent(e);
        }

        downloadCurrentDocument();
    }

}
