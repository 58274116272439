import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Directives
import { AmountDirective } from './directives/amount.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { BlockCopyPasteDirective } from './directives/block-copy-paste.directive';

// Third Party Module
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HHMMSSPipe } from './pipes/hhmmss.pipe';
import { LiveAssignmentService } from '../services/live-assignment.service';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxPayPalModule } from 'ngx-paypal';
import { PaypalComponent } from '../secure-pages/common/paypal/paypal.component';
import { PaymentModalComponent } from '../secure-pages/common/payment-modal/payment-modal.component';
import { MeetDetailsModalComponent } from '../secure-pages/common/meet-details-modal/meet-details-modal.component';
import { StudyDetailsModalComponent } from '../secure-pages/common/study-details-modal/study-details-modal.component';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ExplanationModalComponent } from '../secure-pages/common/explanation-modal/explanation-modal.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ConfirmReattemptComponent } from '../secure-pages/common/confirm-re-attempt/confirm-reattempt.component';
import { Translate1Pipe } from './pipes/translate.pipe';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LightboxModule } from 'ngx-lightbox';
import { ImageModalComponent } from './components/image-modal/image-modal.component';
import { ReplacePipe } from './pipes/replace-text/replace-text.pipe';
import { ChartsModule } from 'ng2-charts';
import { NgxStripeModule } from 'ngx-stripe';
import { GlobalVariable } from '../core/global';
import { FilterPipePipe } from './pipes/filter-pipe.pipe';
import { CalModalComponent } from '../secure-pages/common/cal-modal/cal-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';


export function HttpLoaderFactory(http: HttpClient) {
   return new TranslateHttpLoader(http);
}

export function createTranslateLoader(http: HttpClient) {
   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const directives = [
   NumberOnlyDirective,
   AmountDirective,
   BlockCopyPasteDirective,
   HHMMSSPipe,
   Translate1Pipe,
   FilterPipePipe,
   PaypalComponent,
   MeetDetailsModalComponent,
   PaymentModalComponent,
   ExplanationModalComponent,
   ConfirmReattemptComponent,
   StudyDetailsModalComponent,
   ImageModalComponent,
   ReplacePipe,
   CalModalComponent
];

const imports = [
   FormsModule,
   ReactiveFormsModule,
   NgxPaginationModule,
   NgxScrollToFirstInvalidModule,
   TrimValueAccessorModule,
   NgxIntlTelInputModule,
   NgxSliderModule,
   NgxPayPalModule,
   NgxChartsModule,
   AutocompleteLibModule,
   LightboxModule,
   ChartsModule,
   NgSelectModule
];

const selectedLang = localStorage.getItem('medical_exam_language') ? localStorage.getItem('medical_exam_language') : 'fr';

@NgModule({
   declarations: [
      ...directives,
      HHMMSSPipe
   ],
   imports: [
      CommonModule,
      ...imports,
      BsDatepickerModule.forRoot(),
      TimepickerModule.forRoot(),
      ModalModule.forRoot(),
      AccordionModule.forRoot(),
      TranslateModule.forChild({
         defaultLanguage: selectedLang,
         loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
         },
         isolate: false
      }),
      NgxStripeModule.forRoot(GlobalVariable.stripTest)
   ],
   exports: [
      ...directives,
      ...imports,
      AccordionModule,
      BsDatepickerModule,
      TimepickerModule,
      ModalModule,
      TranslateModule
   ],
   entryComponents: [
      PaymentModalComponent,
      MeetDetailsModalComponent,
      StudyDetailsModalComponent,
      ConfirmReattemptComponent,
      ExplanationModalComponent,
      CalModalComponent,
      ImageModalComponent
   ],
   providers: [
      LiveAssignmentService,
      TranslateService,
      TranslateStore
   ]
})
export class SharedModule {
}
