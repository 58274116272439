import { Component, OnInit } from '@angular/core';
import { GlobalVariable } from '../core/global';
import { HttpService } from '../services/http/http.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from '../services/utility/utility.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html'
})
export class LandingPageComponent implements OnInit {

    language = false;

    constructor(public util: UtilityService) {
        this.util.setLocalData(GlobalVariable.selectedLanguage, GlobalVariable.english.short);
    }

    ngOnInit() {
        // const language = this.util.getLocalData(GlobalVariable.selectedLanguage);
        // if (language === GlobalVariable.french.short) {
        //     this.language = true;
        // }
    }

    // setLang() {
    //     this.translate.setDefaultLang(this.util.getLocalData(GlobalVariable.selectedLanguage));
    //     this.translate.use(this.util.getLocalData(GlobalVariable.selectedLanguage));
    // }
//
}
