import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { UtilityService } from '../utility/utility.service';
import { GlobalVariable } from 'src/app/core/global';

@Injectable({
    providedIn: 'root'
})
export class OTPGuard implements CanActivate {

    constructor(
            private util: UtilityService, private router: Router
    ) {
    }

    canActivate(): boolean {
        const userData = this.util.getLocalData(GlobalVariable.userData, true);
        const token = this.util.getToken();

        if (token) {
            if ((userData && !userData.otp_verified && !userData.social_id)
                    || (userData && !userData.otp_verified && !userData.profile_updated && userData.social_id)) {
                return true;
            } else if ((userData && !userData.password_set && !userData.social_id)
                    || (userData && !userData.password_set && !userData.profile_updated && userData.social_id)) {
                this.router.navigate(['/set-password']);
                return false;
            } else {
                this.router.navigate(['/pages/home']);
                return false;
            }
        }

        this.router.navigate(['']);
        this.util.clearAllLocalData();
        return false;
    }

}
