import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hHMMSS'
})
export class HHMMSSPipe implements PipeTransform {

    transform(value: any, ...args: unknown[]): unknown {
        if (value >= 0) {
            // tslint:disable-next-line:prefer-const variable-name
            let sec_num = parseInt(value, 10);
            const hours = Math.floor(sec_num / 3600);
            const minutes = Math.floor(sec_num / 60) % 60;
            const seconds = sec_num % 60;

            return [hours, minutes, seconds].map(v => v < 10 ? '0' + v : v).filter((v, i) => v !== '00' || i > 0).join(':');
        } else {
            return '00:00';
        }
    }
}
