import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { RoutePartsService } from './services/router-parts.service';
import { SwUpdate } from '@angular/service-worker';
import { UtilityService } from './services/utility/utility.service';
import { GlobalVariable } from './core/global';
import { Subject } from 'rxjs';
import { MessagingService } from './services/message/message.service';

declare const $: any;

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

   appTitle = 'MCCQE1 and NAC OSCE examination';
   pageTitle = '';

   userActivity;
   userInactive: Subject<any> = new Subject();

   constructor(private router: Router, private util: UtilityService, private activeRoute: ActivatedRoute,
               private routePartsService: RoutePartsService, private title: Title,
               private swUpdate: SwUpdate, public message: MessagingService) {   }
               
               setTimeout() {
                     this.userActivity = setTimeout(() => this.userInactive.next(undefined), 900000);
                  // this.userActivity = setTimeout(() => this.userInactive.next(undefined), 9000);
               }
               
               @HostListener('window:mousemove') refreshUserState() {
                  clearTimeout(this.userActivity);
                  this.setTimeout();
               }
               
               ngOnInit() {
                  console.log(localStorage.getItem('medical_exam_token'));
                  
                  if (localStorage.getItem('medical_exam_token') != null) {
                     this.setTimeout();
                     this.userInactive.subscribe(() => {
                        console.log('user has been inactive for 3s');
                        this.message.confirmLogout().then(res => {
                           if (res.isConfirmed && !res.isDismissed) {
                              this.util.clearAllLocalData();
                              this.router.navigate(['/login']);  
                              window.location.reload()
                           } else {
                              return;
                           }
                        })
                     });
                  };

      this.checkNewVersion();
      const language = this.util.getLocalData(GlobalVariable.selectedLanguage);
      if (!language) {
         this.util.setLocalData(GlobalVariable.selectedLanguage, 'en');
      }
      this.changePageTitle();
   }

   changePageTitle() {
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
         const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
         if (!routeParts.length) {
            return this.title.setTitle(this.appTitle);
         }
         // Extract title from parts;
         this.pageTitle = routeParts.reverse().map((part) => part.title).reduce((partA, partI) => `${partA} > ${partI}`);
         this.pageTitle = `${this.appTitle} | ` + this.pageTitle;
         this.title.setTitle(this.pageTitle);
      });
   }

   checkNewVersion() {
      if (this.swUpdate.isEnabled) {
         this.swUpdate.available.subscribe(() => {
            if (confirm('New version available. Load New Version?')) {
               window.location.reload();
            }
         });
      }
   }

}
