import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { UtilityService } from '../services/utility/utility.service';
import { ApiUrl } from '../core/apiUrl';
import { HttpService } from '../services/http/http.service';
import { MessagingService } from '../services/message/message.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


declare const $: any;


@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  constructor(private router: Router, 
    private utilService: UtilityService, 
    private http: HttpService, 
    private messageService: MessagingService,
    private fb: FormBuilder) { 
    
    
  }
  public onClose: Subject<any>;

  ngOnInit() {
    window.scrollTo(0, 0);
    this.allfun();
    this.makeForm()
    
    this.onClose = new Subject()
  }


  goToThisArea(s) {
    console.log(s)
    this.router.navigate(['/index-page'])
    this.utilService.shareStufff.next(`#${s}`)

    this.onClose.next(s)
  }

  burgerCheck
  checkHam(e) {
    console.log(this.burgerCheck)
    this.burgerCheck = !this.burgerCheck
    // e.target.checked = this.burgerCheck
  }


  routeChanged(route) {
    window.scrollTo(0, 0);
    this.router.navigate([route])
  }


  checkChange() {
    this.burgerCheck = false
}

 email
 submitEmail() {
   console.log(this.email)
   let obj = {
      message: this.email
   }
this.http.postDataWithOutLang(ApiUrl.send_mail, obj).subscribe(res => {
console.log(res)
this.email = ''
this.messageService.toast('success', 'Mail has been successfully registered')

})
}

form: FormGroup
ShowError
submitForm() {
   let obj = this.form.value
   console.log(obj)
   if (this.form.valid) {   
    if (!obj.message) {
      delete obj.message
  }
       this.http.postDataWithOutLang(ApiUrl.send_mail_user_info, obj).subscribe(res => {
       console.log(res)
       this.ShowError = true;
   this.messageService.toast('success', 'Our team will connect with you shortly')
   })
   } else {
       this.ShowError = true;
       this.form.markAllAsTouched();

   }
}

makeForm() {
   this.form = this.fb.group({
       name: [[], Validators.required],
       email: [[], [Validators.required, Validators.email]],
       phone_number: [[], [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
       message: ['']
   }) 
}

emailIsValid() {
  return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
}

  allfun(){


    $(document).ready(function(){
      $('.menu-items li a').click(function(){
          $('li a').removeClass("actived");
          $(this).addClass("actived");
      });
      });



      $(function () {
        'use strict';
        $('body').css('paddingTop', $('.navbar').innerHeight());
    
        $('.navbar li a').click(function () {
            console.log('first function hitted');
            $('html, body').animate({
            scrollTop: $($(this).data('scroll')).offset().top - 90
            }, 1000);
            
            $('.navbar a').removeClass('active');
            $(this).addClass('active');
            
            //$(this).addClass('active').parent().siblings().find('a').removeClass('active');
        });
    
        $(window).scroll(function () {
            var winScrollTop = $(this).scrollTop();
            $('.block').each(function () {
            if (winScrollTop > $(this).offset().top) {
                var blockId = '#' + $(this).attr('id');
                $('.navbar li a').each(function () {
                if ($(this).attr('data-scroll') === blockId) {
                    $('.navbar a').removeClass('active');
                    $(this).addClass('active');
                    /*
                    $(this).addClass('active').parent().siblings().find('a').removeClass('active');
                    */
                    
                }
                });
            }
            });
            //Scroll Element
            var toTop = $('.toTop');
            if ($(window).scrollTop() >= 500) {
            if (toTop.is(':hidden')) {//For performance
                toTop.fadeIn().css('display', 'flex');
            }
            } else {
            toTop.fadeOut();
            }
        });
    
        $('.toTop').click(function (e) {
            console.log('last function hitted');
    
            e.preventDefault();
            $('html, body').animate({
            scrollTop: 0
            }, 1000);
        });
        });









  }



}
