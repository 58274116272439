import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UtilityService } from '../utility/utility.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        protected router: Router,
        private util: UtilityService
    ) { }

    canActivate() {
        if (!this.util.getToken()) {
            return true;
        }
        this.router.navigate(['/pages/home']);
        return false;
    }

}
