// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiBaseUrl: 'https://medicalexam-api.wehive.io',
    // apiBaseUrl: 'https://api.mccqbank.com',
    // apiBaseUrl: ' https://dev-api.mccqbank.com',
    apiBaseUrl: 'https://api.mccqbank.com',
    
    firebase: {
        apiKey: 'AIzaSyCqWX-FkNGTWqVPGFeyxQ_rzM0pbtHxAXM',
        authDomain: 'medica-d0c8a.firebaseapp.com',
        projectId: 'medica-d0c8a',
        storageBucket: 'medica-d0c8a.appspot.com',
        messagingSenderId: '823579850709',
        appId: '1:823579850709:web:e3bd3b9e52836d9f962f5c',
        measurementId: 'G-8GPXSB6VJ5'
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
