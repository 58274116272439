import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// guard
import { AuthGuard } from './services/guards/auth.guard';
import { LayoutGuard } from './services/guards/layout.guard';
import { OTPGuard } from './services/guards/otp.guard';
import { SetPasswordGuard } from './services/guards/set-password.guard';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { IndexPageComponent } from './index-page/index-page.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsComponent } from './terms/terms.component';

const routes: Routes = [
   {
      path: '', redirectTo: 'index-page', pathMatch: 'full'
   },
   {
      path: 'index-page', component: IndexPageComponent, canActivate: [AuthGuard]
   },
   {
      path: 'subscriptions', component: SubscriptionsComponent, canActivate: [AuthGuard]
   },
   {
      path: 'about-us', component: AboutUsComponent, canActivate: [AuthGuard]
   },
   {
      path: 'privacy-policy', component: PrivacyPolicyComponent
   },
   {
      path: 'terms', component: TermsComponent, canActivate: [AuthGuard]
   },
   {
      path: 'home', component: LandingPageComponent, canActivate: [AuthGuard]
   },
   {
      path: '', loadChildren: () => import('./login-signup/login-signup.module').then(m => m.LoginSignupModule),
      data: {preload: true, delay: false}, canActivate: [AuthGuard]
   },
   {
      path: 'pages', loadChildren: () => import('./secure-pages/secure-pages.module').then(m => m.SecurePagesModule),
      data: {preload: true, delay: false}, canActivate: [LayoutGuard]
   },
   {
      path: 'otp', loadChildren: () => import('./otp/otp.module').then(m => m.OtpModule),
      data: {preload: true, delay: false, title: 'OTP'}, canActivate: [OTPGuard]
   },
   {
      path: 'reset-password',
      loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
      data: {preload: true, delay: false, title: 'Reset Password'}
   },
   {
      path: 'set-password',
      loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
      data: {preload: true, delay: false, title: 'Set Password'},
      canActivate: [SetPasswordGuard]
   },
   {

      path: 'forgot-password-reset',
      loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
      data: {preload: true, delay: false, title: 'forgot Password'},
   },
   {
      path: '**', redirectTo: 'index-page'
   }
];

@NgModule({
   imports: [RouterModule.forRoot(routes)],
   exports: [RouterModule]
})
export class AppRoutingModule {
}
