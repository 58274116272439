import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GlobalVariable } from 'src/app/core/global';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';

import { loadStripe } from '@stripe/stripe-js';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { FormBuilder } from '@angular/forms';
import { HttpService } from '../../../services/http/http.service';
import { ApiUrl } from '../../../core/apiUrl';
import { MessagingService } from '../../../services/message/message.service';
import { LoaderService } from '../../../services/loader/loader.service';

@Component({
   selector: 'app-plans',
   templateUrl: './payment-modal.component.html'
})
export class PaymentModalComponent implements OnInit {

   public onClose: Subject<{}> = new Subject();
   public planData;
   public global = GlobalVariable;
   language = '';
   selectedPlan: any = {};
   @Output() changeEvent = new EventEmitter<string>();
   @Input() sendData: any = {};
   modalData: any = {};

   @ViewChild(StripeCardComponent, {static: true}) card: StripeCardComponent;

   cardOptions: StripeCardElementOptions = {
      hidePostalCode: true,
      style: {
         base: {
            iconColor: '#666EE8',
            color: '#31325F',
            fontWeight: '300',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSize: '14px',
            '::placeholder': {
               color: '#959592'
            }
         }
      }
   };

   elementsOptions: StripeElementsOptions = {
      locale: 'en'
   };

   constructor(public bsRef: BsModalRef, private util: UtilityService, private fb: FormBuilder,
               private stripeService: StripeService, private http: HttpService, public message: MessagingService,
               private loader: LoaderService) {
   }

   ngOnInit() {
      this.language = this.util.getLocalData(GlobalVariable.selectedLanguage);
   }

   createToken(name): void {
      this.loader.show();
      this.stripeService.createToken(this.card.element, {name}).subscribe((result) => {
         if (result.token) {
            this.apiHit(result.token.id);
            // this.loader.hide();
         } else if (result.error) {
            this.message.toast('error', result.error.message);
            this.loader.hide();
         }
      });
   }

   apiHit(data) {
      const obj: any = {
         language: 'ENGLISH',
         token: data,
         price: this.modalData.price
      };
      this.http.postData(ApiUrl.capture_payment, obj).subscribe(res => {
         this.bsRef.hide();
         this.onClose.next('yes');
      });
   }

   paypalSubmit(e) {
      this.onClose.next('yes');
      this.bsRef.hide();
   }

   // private initConfig(): void {

   // created_at        //         1643700082276
   // currency        //         "$"
   // description
   //         (2) [{…}, {…}]
   // price
   //         19.99
   // showDescription
   //         "Facturé 6 mois 119,94 $"
   // validity
   //         180
   // _id
   //         "61f8df72aaf18e8711682e67"

   // this.payPalConfig = {
   //     currency: GlobalVariable.PAYPAL_CURRENCY,
   //     clientId: GlobalVariable.PAYPAL_CLIENT_ID,
   //     createOrderOnClient: (data) => <ICreateOrderRequest>{
   //         intent: 'CAPTURE',
   //         purchase_units: [
   //             {
   //                 amount: {
   //                     currency_code: GlobalVariable.PAYPAL_CURRENCY,
   //                     value: this.modalData.price,
   //                     breakdown: {
   //                         item_total: {
   //                             currency_code: GlobalVariable.PAYPAL_CURRENCY,
   //                             value: this.modalData.price
   //                         }
   //                     }
   //                 },
   //                 items: [
   //                     {
   //                         name: this.modalData.showDescription,
   //                         quantity: '1',
   //                         category: 'DIGITAL_GOODS',
   //                         unit_amount: {
   //                             currency_code: GlobalVariable.PAYPAL_CURRENCY,
   //                             value: this.modalData.price
   //                         }
   //                     }
   //                 ]
   //             }
   //         ]
   //     },
   //     advanced: {
   //         commit: 'true'
   //     },
   //     style: {
   //         label: 'paypal',
   //         layout: 'vertical'
   //     },
   //     onApprove: (data, actions) => {
   //         console.log('onApprove - transaction was approved, but not authorized', data, actions);
   //         actions.order.get().then(details => {
   //             console.log('onApprove - you can get full order details inside onApprove: ', details);
   //         });
   //     },
   //     onClientAuthorization: (data) => {
   //         this.paypalSubmit('e');
   //         console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
   //         this.showSuccess = true;
   //     },
   //     onCancel: (data, actions) => {
   //         console.log('OnCancel', data, actions);
   //     },
   //     onError: err => {
   //         console.log('OnError', err);
   //     },
   //     onClick: (data, actions) => {
   //         console.log('onClick', data, actions);
   //     }
   // };
   // }

   resetStatus() {

   }

   submitData() {
      this.changeEvent.emit('myyyyyyyyyyyy');
   }

}
