import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe',
})
export class FilterPipePipe implements PipeTransform {

  transform(value: any, filterString: any, propName: any) {
    
    if (!value || value.length === 0) {
      return value;
    }
    const newArray = []
    for (const item of value) {
      if (item[propName].toLowerCase().includes(filterString) || item[propName].includes(filterString)) {
        newArray.push(item)
      }
    }
    
    return newArray;
  }

}
